<template>

  <div id="form-planificacion">
    <div class="modal-header">
    <h1  class="modal-title font-main-bold color-main fs-5">
      Planificación
    </h1>
    <button 
      type="button" 
      class="modal-custom-close" 
      data-bs-dismiss="modal" 
      @click="closeModal()"
      aria-label="Close">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-xl-3 d-flex flex-column">
        <h5 class="font-main text-secondary">Crear Planificación</h5>
        <div class="card flex-grow-1">
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <label class="text-secondary">Nombre</label>
                <input 
                  type="text" 
                  class="form-control input-custom" 
                  v-model="userInputPlan.nombre_planificacion"
                  :disabled="editBool"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary mb-1">Día del viaje</label>
                <ul class="form-days list-inline">
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('1')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '1' ? 'active' : ''" 
                      href="javascript:">
                      L
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('2')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '2' ? 'active' : ''" 
                      href="javascript:">
                      M
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('3')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '3' ? 'active' : ''" 
                      href="javascript:">
                      X
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('4')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '4' ? 'active' : ''" 
                      href="javascript:">
                      J
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('5')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '5' ? 'active' : ''" 
                      href="javascript:">
                      V
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('6')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '6' ? 'active' : ''" 
                      href="javascript:">
                      S
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="diaDelViaje('7')"
                      class="form-days-item"
                      :class="userInputPlan.concurrencia_ejecucion_semanal == '7' ? 'active' : ''" 
                      href="javascript:">
                      D
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">Inscripciones</h6>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="text-secondary mb-1">Inicio del período</label>
                <ul class="form-days list-inline">
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('1')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '1' ? 'active' : ''" 
                      href="javascript:">
                      L
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('2')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '2' ? 'active' : ''" 
                      href="javascript:">
                      M
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('3')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '3' ? 'active' : ''" 
                      href="javascript:">
                      X
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('4')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '4' ? 'active' : ''" 
                      href="javascript:">
                      J
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('5')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '5' ? 'active' : ''" 
                      href="javascript:">
                      V
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('6')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '6' ? 'active' : ''" 
                      href="javascript:">
                      S
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="inicioPeriodoInscripcion('7')"
                      class="form-days-item"
                      :class="userInputPlan.inicio_periodo_semanal == '7' ? 'active' : ''" 
                      href="javascript:">
                      D
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary">Horario de inicio</label>

                <div class="d-flex">
                  <div class="flex-grow-1">
                    <input 
                      class="form-control input-custom" 
                      type="number" 
                      v-model="hora_inicio"
                      min="1" 
                      max="12"
                      placeholder="00"
                      :disabled="editBool"
                      @keyup="selectHora($event,hora_inicio, 'inicio')"
                      @focusout="selectHora($event,hora_inicio, 'inicio-clicked')"
                    >
                  </div>
                  <div class="d-flex justify-content-center align-items-center px-2">
                    :
                  </div>
                  <div class="flex-grow-1">
                    <input 
                      class="form-control input-custom" 
                      type="number" 
                      v-model="min_inicio" 
                      placeholder="00"
                      min="00" 
                      max="59"
                      :disabled="editBool"
                      @keyup="selectMin($event,min_inicio, 'inicio')" 
                      @focusout="selectMin($event,min_inicio, 'inicio-clicked')"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary mb-1">Termino período</label>
                <ul class="form-days list-inline">
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('1')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '1' ? 'active' : ''" 
                      href="javascript:">
                      L
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('2')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '2' ? 'active' : ''" 
                      href="javascript:">
                      M
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('3')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '3' ? 'active' : ''" 
                      href="javascript:">
                      X
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('4')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '4' ? 'active' : ''" 
                      href="javascript:">
                      J
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('5')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '5' ? 'active' : ''" 
                      href="javascript:">
                      V
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('6')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '6' ? 'active' : ''" 
                      href="javascript:">
                      S
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a 
                      @click="terminoPeriodoInscripcion('7')"
                      class="form-days-item"
                      :class="userInputPlan.final_periodo_semanal == '7' ? 'active' : ''" 
                      href="javascript:">
                      D
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary mb-1">Horario del termino</label>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <input 
                      class="form-control input-custom" 
                      type="number" 
                      min="1" 
                      max="12"
                      v-model="hora_termino" 
                      :disabled="editBool"
                      @keyup="selectHora($event,hora_termino, 'termino')" 
                      @focusout="selectHora($event,hora_termino, 'termino-clicked')"
                    >
                  </div>
                  <div class="d-flex justify-content-center align-items-center px-2">
                    :
                  </div>
                  <div class="flex-grow-1">
                    <input 
                      class="form-control input-custom" 
                      type="number" 
                      v-model="min_termino" 
                      min="0" 
                      max="59"
                      :disabled="editBool" 
                      @keyup="selectMin($event,min_termino, 'termino')" 
                      @focusout="selectMin($event,min_termino, 'termino-clicked')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-3 d-flex flex-column">
        <h5 class="font-main text-secondary mt-3 mt-lg-0">Recurrencia personalizada</h5> 
        <div class="card flex-grow-1">
          <div class="card-body">

            <div class="row">
              <div class="row">
                <div class="col-12">
                  <label class="text-secondary">Repetir cada</label>
                </div>
              </div>
              <div class="col-5">
                <input 
                  type="number" 
                  class="form-control input-custom" 
                  min="1" 
                  :max="max_value_repetirCada"
                  v-model="userInputPlan.concurrencia_tiempo" 
                  :disabled="editBool" 
                  autocomplete="off" 
                />
              </div>
              <div class="col-7">
                <v-select
                  class="selvue-custom"
                  v-model="select_cadencia"
                  label="nombre_cadencia"
                  :options="cadencia_list"
                  :clearable="false"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary">Fecha inicio</label>
                <div class="position-relative">
                  <div 
                    class="form-control input-calendar" 
                    @click="openCalendar('start-date')">
                    <span>{{ dateFormatymd(init_date) }}</span>
                    <div class="icon">
                      <font-awesome-icon icon="calendar"/>
                    </div>
                  </div>
                  <div
                    @click="deleteDate('start-date')"
                    v-show="date_start != ''"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown"
                  :class="calendar_side" 
                  v-if="show_calendar_inicio"
                >
                  <v-calendar 
                    color="blue" 
                    :min-date="min_date" 
                    :max-date="max_date" 
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side"
                  >
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <label class="text-secondary">Fecha termino</label>
                <div class="position-relative">
                  <div 
                    class="form-control input-calendar" 
                    @click="openCalendar('end-date')">
                    <span>{{ dateFormatymd(end_date) }}</span>
                    <div class="icon">
                      <font-awesome-icon icon="calendar"/>
                    </div>
                  </div>
                  <div
                    @click="deleteDate('end-date')"
                    v-show="date_end != ''"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>

              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown"
                  :class="calendar_side" 
                  v-if="show_calendar_termino"
                >
                  <v-calendar 
                    color="blue" 
                    :min-date="min_date" 
                    :max-date="max_date" 
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side"
                  >
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 d-flex flex-column">
        <h5 class="font-main text-secondary mt-3 mt-lg-0">Seleccionar tramos</h5>
        <div class="card flex-grow-1 scroll-custom-550">
          <div class="card-body">         
            <table class="table table-custom table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Tramo</th>
                  <th scope="col">Hora de salida</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(tramo, i) in shown_tramos" 
                  :key="i"
                >
                  <td class="th th-tramo align-top">
                    <div class="d-flex align-items-top">
                      <div class="flex-shrink-0">
                        <input 
                          v-if="editBool == false" 
                          :id="i"
                          class="form-check-input me-1"
                          type="checkbox"
                          value="tramo" 
                          @change="changeAddedTramosList(i, tramo)" 
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <label 
                          :for="i"
                          class="form-check-label w-100 link-cursor" 
                        >
                          <p class="text-secondary mb-0">
                            <i class="fa-solid fa-arrow-right-arrow-left color-secondary"></i>
                            {{ tramo.nombre_origen }} a {{ tramo.nombre_destino }}<br>
                            <small>
                              <span v-if="tramo.transporte_tipo_medio.transporte_tipo.nombre_transporte === 'Aéreo' ">
                                <i class="fa-solid fa-plane color-light"></i>
                              </span>
                              <span v-if="tramo.transporte_tipo_medio.transporte_tipo.nombre_transporte === 'Terrestre' ">
                                <i class="fa-solid fa-car color-light"></i>
                            </span>
                              {{ tramo.transporte_tipo_medio.transporte_tipo.nombre_transporte }} 
                              |
                              {{ tramo.transporte_tipo_medio.cantidad_asiento }}
                              asientos
                            </small>
                          </p>
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="th th-hora-salida w-170">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <input 
                          class="form-control input-custom" 
                          type="number" 
                          v-model="horas[i]" 
                          min="1" 
                          max="23"
                          @keyup="selectHoraTramo($event,i, horas[i], '')" 
                          @click="selectHoraTramo($event,i, horas[i], 'clicked')" 
                        />
                      </div>
                      <div class="d-flex justify-content-center align-items-center px-2">
                        :
                      </div>
                      <div class="flex-grow-1">
                        <input 
                          class="form-control input-custom" 
                          type="number" 
                          v-model="mins[i]"
                          min="0" 
                          max="59"
                          @click="selectMinTramo(i, mins[i])" 
                          @keyup="selectMinTramo(i, mins[i])" 
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>  
          </div>
        </div>
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus" 
    />
    <Spinner v-if="show_spinner"/>
  </div>

  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";

export default {
  components: { Spinner, Status },

  data() {
    return {
      accion: "Crear",
      show_spinner: false,

      // Crear planificacion
      hora_inicio: "01",
      min_inicio: "00",
      horario_inicio: "AM",

      hora_termino: "01",
      min_termino: "00",
      horario_termino: "PM",

      // Recurrencia personalizada
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      max_value_repetirCada: "8",
      open_cadencia: false,
      cadencia: "Semanal",
      show_calendar_inicio: false,
      show_calendar_termino: false,
      calendar_side: "",
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "", // enviar
      end_calendar: false, //termino
      date_end: "", //enviar
      init_date: "Inicio",
      end_date: "Término",

      // seleccionar tramo
      checks: [],
      horas: [],
      mins: [],
      horarios: [],

      // Editar hora tramo
      horas_edit: [],
      mins_edit: [],
      horarios_edit: [],

      modal_status_msg: "",
      modal_status: false,
      open_modal_status: false,

      //FF
    select_cadencia: { __typename: "Cadencia", id_cadencia: "2", nombre_cadencia: "Semanal" },

      shown_tramos: [],

      userInputPlan: {
        id_planificacion: '0',
        id_cadencia: '2',
        nombre_planificacion: '',
        concurrencia_ejecucion_semanal: null,
        concurrencia_ejecucion_day: '-1',
        concurrencia_ejecucion_month: '-1',
        concurrencia_tiempo: '1',
        inicio_periodo_semanal: null,
        inicio_periodo_day: '-1',
        inicio_periodo_month: '-1',
        hora_inicio_periodo: '08:00:00',
        final_periodo_semanal: null,
        final_periodo_day: '-1',
        final_periodo_month: '-1',
        hora_final_periodo: '12:00:00',
        fecha_inicio_ejecucion: '',
        fecha_final_ejecucion: '',
        tramos: []
      }
    };
  },

  watch: {
    userInputPlan: {
      handler(val) {
        this.$emit("userInputChange", val);
      },
      deep: true
    }
  },

  computed: {
    ...mapState("transporteModule", [
      "tramos_list",
      "cadencia_list",
      "editingPlanificacion",
    ]),

    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },

    editBool() {
      return Object.keys(this.editingPlanificacion).length !== 0;
    }
  },

  async created() {
    this.$emit("userInputChange", this.userInputPlan);

    this.show_spinner = true;
    if (this.editBool == false) {
      const data = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      };
      await this.getTramosEmpresa(data);
      await this.getCadenciaPlanificacion();

      this.shown_tramos = this.tramos_list;

      this.tramos_list.forEach(() => {
        this.checks.push(false);
        this.horas.push("01");
        this.mins.push("00");
        this.horarios.push("AM");
      });
      //  en caso de que no hayan tramos
      if (this.tramos_list.length == 0) {
        this.open_modal_status = true;
        this.modal_status = false;
        this.modal_status_msg = "No hay tramos para seleccionar.";
      }
    } else {
      this.setDataForEditingPlan();
    }

    this.show_spinner = false;
  },
  methods: {
    ...mapActions("transporteModule", [
      "getTramosEmpresa",
      "getCadenciaPlanificacion",
    ]),

    setDataForEditingPlan() {
      this.accion = "Editar";
      Object.assign(this.userInputPlan, {
        nombre_planificacion: this.editingPlanificacion.nombre_planificacion,
        concurrencia_ejecucion_semanal: this.editingPlanificacion.tranpsorte_planificacion_detalle.concurrencia_ejecucion_semanal,
        inicio_periodo_semanal: this.editingPlanificacion.tranpsorte_planificacion_detalle.inicio_periodo_semanal,
        final_periodo_semanal: this.editingPlanificacion.tranpsorte_planificacion_detalle.final_periodo_semanal,
        id_planificacion: this.editingPlanificacion.id_planificacion
      })
      this.getHoraInicio(this.editingPlanificacion.tranpsorte_planificacion_detalle.hora_inicio_periodo);
      this.getHoraTermino(this.editingPlanificacion.tranpsorte_planificacion_detalle.hora_final_periodo);
      this.selectCadencia(this.editingPlanificacion.transporte_planificacion_cadencia);
      this.init_date = this.editingPlanificacion.tranpsorte_planificacion_detalle.fecha_inicio_ejecucion;
      this.end_date = this.editingPlanificacion.tranpsorte_planificacion_detalle.fecha_final_ejecucion;

      this.editingPlanificacion.transporte_tramo.forEach((tramo) => {
        this.horas.push(this.getHoraEdit(tramo.hora_viaje));
        this.mins.push(this.getMinEdit(tramo.hora_viaje));
        this.horarios.push(this.gethorarioEdit(tramo.hora_viaje));
        this.horas_edit.push(this.getHoraEdit(tramo.hora_viaje));
        this.mins_edit.push(this.getMinEdit(tramo.hora_viaje));
        this.horarios_edit.push(this.gethorarioEdit(tramo.hora_viaje));
      });
      this.setTramosForEditing();
    },

    closeModal() {
      this.$emit("close-modal");
    },

    getHoraEdit(hora) {
      let h = hora[0] + hora[1];
      if (h.length == 1) h = "0" + h;
      return h;
    },

    getMinEdit(hora) {
      let m = hora[3] + hora[4];
      if (m.length == 1) m = "0" + m;
      return m;
    },

    gethorarioEdit(hora) {
      let h = hora[0] + hora[1];
      h = parseInt(h);
      if (h > 11) return "PM";
      else return "AM";
    },

    // Crear planificacion
    diaDelViaje(n) {
      if (this.editBool == false)
        this.userInputPlan.concurrencia_ejecucion_semanal = n;
    },

    inicioPeriodoInscripcion(n) {
      if (this.editBool == false)
        this.userInputPlan.inicio_periodo_semanal = n;
    },

    get_hora(hora, min) {
      if (hora.length == "") hora = "08";
      if (min.length == "") min = "00";
      let hour = parseInt(hora);
      hour = hour.toString();
      if (hour.length == 1) hour = "0" + hour;
      if (min.length == 1) min = "0" + min;
      return hour + ":" + min + ":00";
    },

    selectHora(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if( key !=  8){
        //backspace pressed
        if (this.validateOnlyNumber(status)) {

        if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
          if (status.length == 1 ) {
            if (status < 10 && status >= 1) {
            status = '0'+status
            } 
          }
          if (status.length > 2 || status == '0') {
            status == '00';
          }
        }
        if (status > 23) {
          status = '00';
        } 
          if (tipo == "inicio" || tipo == "inicio-clicked") {
          this.hora_inicio = status;
          this.userInputPlan.hora_inicio_periodo = this.get_hora(
            this.hora_inicio,
            this.min_inicio,
            this.horario_inicio
          );
        }
        if (tipo == "termino" || tipo == "termino-clicked") {
          this.hora_termino = status;
          this.userInputPlan.hora_final_periodo = this.get_hora(
            this.hora_termino,
            this.min_termino,
            this.horario_termino
          );
        }
      } else {
        if (tipo == "inicio") this.hora_inicio = "";
        if (tipo == "termino") this.hora_termino = "";
      }
      }
     
    },

    selectMin(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        if (this.validateOnlyNumber(status)) {
          if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
            if (status.length == 1) {
              if (status < 10 && status >= 1) {
                status = '0'+status
              } 
             if (status == '0') {
              status = '00';
            }
          }
          }
          if (status > 59) {
            status = '00';
          }
        
          if (tipo == "inicio" || tipo == "inicio-clicked") {
            this.min_inicio = status;
            this.userInputPlan.hora_inicio_periodo = this.get_hora(
              this.hora_inicio,
              this.min_inicio,
              this.horario_inicio
            );
          }
          if (tipo == "termino" || tipo == "termino-clicked") {
            this.min_termino = status;
            this.userInputPlan.hora_final_periodo = this.get_hora(
              this.hora_termino,
              this.min_termino,
              this.horario_termino
            );
          }
        } else {
          if (tipo == "inicio") this.min_inicio = "";
          if (tipo == "termino") this.min_termino = "";
        } 
      }
    },

    validateOnlyNumber(text) {
      let num_regex = /^[0-9]*$/g;
      if (!num_regex.test(text)) {
        return false;
      } else {
        return true;
      }
    },

    terminoPeriodoInscripcion(n) {
      if (this.editBool == false)
        this.userInputPlan.final_periodo_semanal = n;
    },

    selectCadencia(status) {
      if (status.id_cadencia == "1") {
        if (this.userInputPlan.id_cadencia == "2")
          this.userInputPlan.concurrencia_tiempo = "6";
        this.max_value_repetirCada = "6";
      } else if (status.id_cadencia == "2") {
        this.max_value_repetirCada = "8";
      }
      this.cadencia = status.nombre_cadencia;
      this.userInputPlan.id_cadencia = status.id_cadencia;
      this.open_cadencia = false;
    },

    handleCalendar() {
      this.show_calendar_inicio = false;
      this.show_calendar_termino = false;
    },

    openCalendar(side) {
      if (this.editBool == false) {
        this.calendar_side = side;
        if (this.calendar_side == "start-date") {
          this.show_calendar_inicio = true;
          this.min_date = new Date();
          if (this.end_calendar == true) {
            this.max_date = this.date_end;
          }
        }
        if (this.calendar_side == "end-date") {
          this.show_calendar_termino = true;
          this.max_date = "";
          if (this.start_calendar == true) {
            this.min_date = this.date_start;
          }
        }
      }
    },
    deleteDate(status){
      if(status == 'start-date' ) {
        this.date_start = '';
          this.init_date = "Inicio";
        this.userInputPlan.fecha_inicio_ejecucion = '';
      }
      if(status == 'end-date' ) {
        this.date_end = '';
        this.end_date = "Término";
        this.userInputPlan.fecha_final_ejecucion = '';
      }
    },

    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = this.get_date(day.id);
        this.init_date = day.id;
        this.userInputPlan.fecha_inicio_ejecucion = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = this.get_date(day.id);
        this.end_date = day.id;
        this.userInputPlan.fecha_final_ejecucion = day.id;
      }
      console.log("init: " + this.date_start + ", end: " + this.date_end);
      this.handleCalendar();
    },

    get_date(day) {
      let dia = day.toString() + "T00:00:00";
      return dia;
    },

    changeAddedTramosList(i) {
      // TODO: changeAddedTramosList(event, i, tramo), y luego:
      // if (event.traget.checked) {
      //   this.agregarTramo
      // }
      if (this.checks[i] == true) {
        this.checks[i] = false;
      } else {
        this.checks[i] = true;
      }
      this.agregarTramos();
    },

    agregarTramos() {
      this.userInputPlan.tramos = [];
      for (let i = 0; i < this.tramos_list.length; i++) {
        if (this.checks[i] == true) {
          if (this.horas[i] == "") this.horas[i] = "00";
          if (this.mins[i] == "") this.mins[i] = "00";
          this.userInputPlan.tramos.push({
            id_tramo: "0",
            id_destino: this.tramos_list[i].id_destino,
            id_ida_vuelta: "2",
            hora_viaje: this.get_hora(this.horas[i], this.mins[i], this.horarios[i]),
            index_principal: "-1",
            index_coincidente: "-1",
            tipo_tramo: "1",
          });
        }
      }
    },

    selectHoraTramo(e,i, hora_t,tipo) {
      let key = e.keyCode || e.charCode;
      if( key !=  8){
        //backspace pressedW
        if (this.validateOnlyNumber(hora_t)) {
          if (tipo == 'clicked') {
             
          if (hora_t.length == 1 ) {
            if (hora_t < 10 && hora_t >= 1) {
              hora_t = '0'+hora_t
            }
           
            if (hora_t == '0') {
              hora_t = '00';
            }
          }
         if (hora_t.length > 2 || hora_t > 23) {
            hora_t == '00';
          }
        }
        
          if (!this.editBool) {
            for (let h = 0; h < this.horas.length; h++) {
              if (i == h) this.horas[h] = hora_t;
              break;
            }
            this.agregarTramos();
          } else {
          this.selectHoraTramoEdit(i, hora_t);
        }
      } else {
          this.horas[i] = "";
        }
      } 
    },

    selectMinTramo(e, i, min_t, tipo) {
      let key = e.keyCode || e.charCode;
      if( key !=  8){
        //backspace pressedW
        if (this.validateOnlyNumber(min_t)) {
          if (tipo == 'clicked') {
            if (min_t.length == 1 ) {
              if (min_t < 10 && min_t >= 1) {
                min_t = '0'+min_t
              }
              if (min_t == '0') {
                min_t = '00';
              }
            }
            if (min_t.length > 2 || min_t > 23) {
              min_t == '00';
            }
          }
          if (!this.editBool) {
            this.mins[i] = min_t;
            this.agregarTramos();
          } else {
            this.selectMinTramoEdit(i, min_t);
          }
        } else {
          this.mins[i] = "";
        }
      }
    },

    setTramosForEditing() {
      this.userInputPlan.tramos = [];
      this.shown_tramos = [];
      for (let i = 0; i < this.horas.length; i++) {
        this.shown_tramos.push({
          id_tramo: this.editingPlanificacion.transporte_tramo[i].id_tramo,
          id_destino: "1",
          hora_viaje: this.get_hora(this.horas[i], this.mins[i], this.horarios[i]),
          fecha_viaje: this.editingPlanificacion.transporte_tramo[i].fecha_viaje,
          nombre_origen: this.editingPlanificacion.transporte_tramo[i].transporte_lista_destino.nombre_origen,
          nombre_destino: this.editingPlanificacion.transporte_tramo[i].transporte_lista_destino.nombre_destino,
          transporte_tipo_medio: this.editingPlanificacion.transporte_tramo[i].transporte_lista_destino.transporte_tipo_medio,
        });

        this.userInputPlan.tramos.push({
          id_tramo: this.editingPlanificacion.transporte_tramo[i].id_tramo,
          id_destino: "1",
          hora_viaje: this.get_hora(this.horas_edit[i], this.mins_edit[i], this.horarios_edit[i]),
          fecha_viaje: this.editingPlanificacion.transporte_tramo[i].fecha_viaje,
        });
      }
    },

    selectHoraTramoEdit(i, hora) {
      this.horas_edit[i] = hora;
      this.setTramosForEditing();
    },

    selectMinTramoEdit(i, min) {
      this.mins_edit[i] = min;
      this.setTramosForEditing();
    },

    horarioTramoEdit(i, horario) {
      this.horarios_edit[i] = horario;
      this.setTramosForEditing();
    },

    // Si no hay tramos, redirige a tramos
    acceptStatus() {
      this.$router.push("./tramos");
    },

    getHoraInicio(hora) {
      this.hora_inicio = hora[0] + hora[1];
      this.min_inicio = hora[3] + hora[4];
    },
    getHoraTermino(hora) {
      this.hora_termino = hora[0] + hora[1];
      this.min_termino = hora[3] + hora[4];
    }
  },
};
</script>