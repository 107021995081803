<template>

  <section id="planificaciones">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-bus-simple color-secondary"></i>
          En esta seccion podras visualizar las planificaciones creadas o crear nuevas
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button 
          v-if="planificacionesArr.length > 0"
          @click="open_modal = true"
          data-bs-toggle="modal" 
          data-bs-target="#modal-form-planificaciones"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear planificación
      </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <MisPlanificacionesTable 
          :planificaciones="planificacionesArr"
          @open-modal-create="open_modal = true"
          @open-modal-edit="onOpenModalForEdit"
        />
        <Spinner v-if="show_spinner"/>

        <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
          <paginate
          v-model="pagination.actual_page"
          :page-count="pagination.page_count"
          :page-range="pagination.page_range"
          :click-handler="pagination.click_handler"
          :prev-text="pagination.prev_text"
          :next-text="pagination.next_text"
          :container-class="pagination.container_class"
          :page-class="pagination.page_class"
          >
          </paginate>
        </div>    
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-form-planificaciones" 
      tabindex="-1" 
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <CrearPlanificacion 
            v-if="open_modal === true"
            @userInputChange="onUserInputChange"
            @close-modal="closeModal()" 
          />
          <div class="modal-footer">
            <button 
              type="button" 
              ref="closeModal"
              class="btn btn-custom-color-white rounded-pill mw-100" 
              @click="closeModal()"
              data-bs-dismiss="modal">
              <i class="fa-solid fa-angle-left pe-1"></i>
              Volver
            </button>
            <button 
              type="button" 
              class="btn btn-custom-color-blue rounded-pill mw-100"
              @click="addOrEdit()">
              {{nameAction()}}
            </button>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import CrearPlanificacion from "./CrearPlanificacion.vue";
import MisPlanificacionesTable from "./MisPlanificacionesTable.vue";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {
  components: {
    CrearPlanificacion,
    MisPlanificacionesTable,
    Spinner
  },

  data() {
    return {
      show_spinner: false,
      open_modal: false,
      editBool: false,

      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },
      planificaciones_limit: '10',

      userInputPlan: {}
      
    };
  },

  computed: {
    ...mapState("transporteModule", [
      "planificaciones_list", 
      "editingPlanificacion",
      "planificacionesPaginationArr",
      "planificacionesArr"
    ])
  },

  mounted() {
    this.searchPlanificaciones();
  },

  methods: {
    ...mapActions("transporteModule", [
      "createPlanificacionAction",
      "editPlanificacionAction",
      "cleanEditingPlanAction",
      "getPlanificacionById",
      "getPlanificacionesPagination",
      "getPlanificacionesPaginated"
    ]),

    nameAction(){
      if (this.editBool == true) return 'Guardar';
      else return 'Añadir';
    },

    // Planificaciones
    async searchPlanificaciones(){
      this.show_spinner = true;
      await this.getPlanificacionesPages();
      if(this.planificacionesPaginationArr.length > 0){
        this.pagination.actual_page = 1;
        await this.getPlanificacionesByPage(this.pagination.actual_page);
      }
      this.show_spinner = false;
    },

    async getPlanificacionesPages(){
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        limite: this.planificaciones_limit,
      }
      await this.getPlanificacionesPagination(data_);
      this.pagination.page_count = this.planificacionesPaginationArr.length;
    },

    async getPlanificacionesByPage(page){
      this.show_spinner = true;
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_planificacion: this.planificacionesPaginationArr[(page -1)].planificaciones,
      }
      await this.getPlanificacionesPaginated(data_);
      this.show_spinner = false;
    },

    async changePage(page){
      this.show_spinner = true;
      this.pagination.actual_page = page;
      await this.getPlanificacionesByPage(this.pagination.actual_page);
      this.show_spinner = false;
    },

    validateWeekdayOrder() {
      const viaje = this.userInputPlan.concurrencia_ejecucion_semanal;
      const inicio = this.userInputPlan.inicio_periodo_semanal;
      const cierre = this.userInputPlan.final_periodo_semanal;
      // El dia del viaje no puede estar entre inicio y cierre
      if (cierre < inicio && inicio < viaje) return false;
      if (inicio < viaje  && viaje < cierre) return false;
      if (viaje < cierre && cierre < inicio) return false;
      // if (viaje < inicio && inicio == cierre) return false;

      return true;
    },

    async addOrEdit() {
      if (this.validatePlanFields() == false) {
        this.toastPlanificacionValidationIncomplete();
        return;
      }
      
      if (this.validateWeekdayOrder() == false) {
        this.toastWeekdaysInvalid();
        return;
      }

      if (this.editBool === true) {
        await this.editPlanificacion();
      } else {
        await this.addPlanificacion();
      }
      this.$refs.closeModal.click();
    },

    async addPlanificacion() {
      this.open_modal = false;
      this.show_spinner = true;

      if (this.userInputPlan.id_cadencia == '1') {
        this.userInputPlan.concurrencia_ejecucion_semanal = '-1';
        this.userInputPlan.inicio_periodo_semanal = '-1';
        this.userInputPlan.final_periodo_semanal = '-1';
      }
      const payload = {
        planificacion: this.userInputPlan,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };

      const res = await this.createPlanificacionAction(payload);
      if (res) {
        this.clean();
        this.open_modal = false;
        this.toastPlanificacionCreateSuccess();
      } else {
        this.toastPlanificacionCreateError();
      }
      this.show_spinner = false;
    },

    async editPlanificacion() {
      this.show_spinner = true;
      const payload = {
        planificacion: { 
          id_planificacion: this.userInputPlan.id_planificacion,
          tramos: this.userInputPlan.tramos
        },
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };

      const res = await this.editPlanificacionAction(payload);
      if (res) { 
        this.clean();
        this.open_modal = false;
        this.toastPlanificacionEditSuccess();
      } else {        
        this.toastPlanificacionEditError();
      }
      this.show_spinner = false;
    },

    clean(){
      this.cleanEditingPlanAction();
      this.editBool = false,
      this.userInputPlan = {};
    },

    closeModal(){
      this.clean();
      this.open_modal = false;
    },

    validatePlanFields() {
      if (this.editBool == false) {
        if (this.userInputPlan.id_cadencia == '2') {
          return ((this.userInputPlan.nombre_planificacion != '') && 
          (this.userInputPlan.concurrencia_ejecucion_semanal != null) &&
          (this.userInputPlan.inicio_periodo_semanal != null) &&
          (this.userInputPlan.final_periodo_semanal != null) &&
          (this.userInputPlan.fecha_inicio_ejecucion != '') &&
          (this.userInputPlan.fecha_final_ejecucion != '') &&
          (this.userInputPlan.tramos.length > 0));
        } else if (this.userInputPlan.id_cadencia == '1') {
          return ((this.userInputPlan.nombre_planificacion != '') && 
          (this.userInputPlan.fecha_inicio_ejecucion != '') &&
          (this.userInputPlan.fecha_final_ejecucion != '') &&
          (this.userInputPlan.tramos.length > 0));
        }
      } else {
        return true;
      }
    },

    onUserInputChange(userInputObj) {
      this.userInputPlan = userInputObj;
    },
    
    async onOpenModalForEdit() {
      this.editBool = true;
      this.open_modal = true;
    },

    toastPlanificacionCreateSuccess() {
      this.$toast.open({
        message: `Planificación creada correctamente.`,
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastPlanificacionCreateError() {
      this.$toast.open({
        message: `Ha ocurrido un problema. Por favor inténtalo nuevamente.`,
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastPlanificacionEditSuccess() {
      this.$toast.open({
        message: `Planificación editada correctamente.`,
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastPlanificacionEditError() {
      this.$toast.open({
        message: `Ha ocurrido un problema. Por favor inténtalo nuevamente.`,
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastPlanificacionValidationIncomplete() {
      this.$toast.open({
        message: 'Por favor completa los campos que faltan.',
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastWeekdaysInvalid() {
      this.$toast.open({
        message: 'El día del viaje no puede quedar entre el día de inicio de inscripción\
          y el día de término de inscripción.',
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    }
  },
};
</script>
