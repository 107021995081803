<template>
  <div class="table-container">
    <table class="table table-custom table-borderless table-custom-responsive mb-0">
      <thead>
        <tr>
          <th scope="col">Planificación</th>
          <th scope="col">Total de viajes realizados</th>
          <th scope="col">Cantidad de tramos</th>
          <th scope="col">Día del viaje</th>
          <th scope="col">Inicio periodo inscripción</th>
          <th scope="col">Término periodo inscripción</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(p, i) in planificaciones" 
          :key="i">
          <td class="th th-planificacion">
            {{ p.nombre_planificacion }}
          </td>
          <td class="th th-total-viajes-realizados">
            {{viajesRealizados(p)}}
          </td>
          <td class="th th-cantidad-tramos">
            {{ p.transporte_tramo.length }}
          </td>
          <td class="th th-dia-viaje">
            {{ diaViaje(p) }}
          </td>
          <td class="th th-inicio-periodo-inscripcion">
            {{ inicioPeriodo(p)}}
          </td>
          <td class="th th-termino-periodo-inscripcion">
            {{ terminoPeriodo(p) }}
          </td>
          <!-- Acciones -->
          <td class="th th-accion text-center">
            <div class="dropdown dropdown-custom d-flex justify-content-center">
                <button 
                  class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                  type="button"
                  data-bs-toggle="dropdown">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a 
                      @click="editarPlan(p)"
                      data-bs-toggle="modal" 
                      data-bs-target="#modal-form-planificaciones"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                      Editar
                    </a>
                  </li>
                  <li>
                    <a 
                      v-if="isPlanAvailableForDelete(p)"
                      @click="eliminarPlan(p)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                      Eliminar
                    </a>
                  </li>
                </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="transporte-container__no-data"  v-if="planificaciones.length==0">
      <img
        class="transporte-container__img-question"
        src="@/assets/img/utilities/question_face.svg"
        alt=""
      />
      <p>Aún no se han registrado planificaciones</p>
      <button 
        class="tramos-add__boton" 
        @click="openModalCreate()"
        data-bs-toggle="modal" 
        data-bs-target="#modal-form-planificaciones"
      >
        + Crear planificación
      </button>
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
  </div>
</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Question, Status, 
  },

  props: {
    planificaciones: {
      type: Array,
    },
  },

  data(){
    return {
      show_no_result: false,
      dia: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      show_spinner: false,

      open_question_modal: false,
      question_modal_msg: '',

      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,

      id_delete:'',
    };
  },

  computed: {
    ...mapState("transporteModule", ["planificaciones_list", "viajesEnCurso_list"]),
  },

  async created() {
    this.show_spinner = true;
    const data = {
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa, 
      tipoRango: "0",
      fechaInicio: "",
      fechaFin: "",
      limit: "1000",
      fechaId: "",
    };
    await this.getViajesEnCurso(data);
    this.show_spinner = false;
  },

  watch:{
    planificaciones() {
      if(this.planificaciones.length > 0) this.show_no_result = false;
      else this.show_no_result = true;
    }
  },

  methods: {
    ...mapActions("transporteModule", [
      "getViajesEnCurso", 
      "deletePlanificacionAction", 
      "setEditingPlanAction"
    ]),

    openModalCreate() {
      this.$emit("open-modal-create");
    },

    viajesRealizados(planificacion){
      let i=0;
      for(i=0; i<planificacion.transporte_tramo.length; i++){
        if(this.getTramoActual(planificacion.transporte_tramo[i]) == true){
          break;
        }
      }
      return i
    },

    // Columna dia de viaje
    diaViaje(p){
      let hoy = new Date();
      let ultima = p.transporte_tramo.length -1;
      let fechaFinal = new Date(p.transporte_tramo[ultima].fecha_viaje+'T00:00:00') ;
      if(hoy.getTime() > fechaFinal.getTime()){
        return "Planificación terminada";
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Semanal"){
        return this.findNextTramo(p, 'Dia de viaje');
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Diario"){
        return this.findNextTramo(p, 'Dia de viaje');
      }
      else{
        return "Por definir";
      }
    },

    // Columna inicio periodo
    inicioPeriodo(p){
      let hoy = new Date();
      let ultima = p.transporte_tramo.length -1;
      let fechaFinal =new Date(p.transporte_tramo[ultima].fecha_viaje+'T00:00:00') ;
      if(hoy.getTime() > fechaFinal.getTime()){
        return "-";
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Semanal"){
        return this.findNextTramo(p, 'Inicio');
        // return this.dia[p.tranpsorte_planificacion_detalle.inicio_periodo_semanal]
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Diario"){
        return "Diario";
        // return this.getDateName(this.getToday(), p.tranpsorte_planificacion_detalle.hora_inicio_periodo)
      }
      else{
        return "Por definir";
      }
    },

    // Columna termino periodo
    terminoPeriodo(p){
      let hoy = new Date();
      let ultima = p.transporte_tramo.length -1;
      let fechaFinal =new Date(p.transporte_tramo[ultima].fecha_viaje+'T00:00:00') ;
      if(hoy.getTime() > fechaFinal.getTime()){
        return "-";
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Semanal"){
        return this.findNextTramo(p, 'Fin');
        // return this.dia[p.tranpsorte_planificacion_detalle.final_periodo_semanal]
      }
      if(p.transporte_planificacion_cadencia.nombre_cadencia == "Diario"){
        return this.getYesterday(p);
      }
      else{
        return "Por definir";
      }
    },

    getYesterday(planificacion){
      let tramo = {};
      for(let i=0; i<planificacion.transporte_tramo.length; i++){
        if(this.getTramoActual(planificacion.transporte_tramo[i]) == true){
          tramo = planificacion.transporte_tramo[i];
          break;
        }
      }
      let day = new Date(tramo.fecha_viaje + 'T00:00:00')
      let previous = new Date(day);
      let yesterday = new Date(previous.setDate(previous.getDate()-1))

      let month = yesterday.getMonth() +1;
      month = month.toString();
      if(month.length == 1) month = '0' + month;
      let dia = yesterday.getDate();
      dia = dia.toString();
      if(dia.length == 1) dia = '0' + dia;

      let ayer = yesterday.getFullYear() + '-' + month + '-' +dia;
      return this.getDateName(ayer, planificacion.tranpsorte_planificacion_detalle.hora_final_periodo)
      // return 'Hoy'
    },

    findNextTramo(planificacion, tipo){
      let tramo = {};
      for(let i=0; i<planificacion.transporte_tramo.length; i++){
        if(this.getTramoActual(planificacion.transporte_tramo[i]) == true){
          tramo = planificacion.transporte_tramo[i];
          break;
        }
      }
      if(tipo == 'Dia de viaje') {
        return this.getDateName(tramo.fecha_viaje, tramo.hora_viaje);
      } else if (tipo == 'Inicio') {
        let days = this.getDateArr(tramo.fecha_viaje);
        let day = this.getDayWeek(days, planificacion.tranpsorte_planificacion_detalle.inicio_periodo_semanal);
        return this.getDateName(day, planificacion.tranpsorte_planificacion_detalle.hora_inicio_periodo);
      } else if (tipo == 'Fin') {
         let days = this.getDateArr(tramo.fecha_viaje);
         let day = this.getDayWeek(days, planificacion.tranpsorte_planificacion_detalle.final_periodo_semanal);
         return this.getDateName(day, planificacion.tranpsorte_planificacion_detalle.hora_final_periodo);
      }
    },

    getToday(){
      let day = new Date();
      let month = day.getMonth() +1;
      month = month.toString();
      if(month.length == 1) month = '0' + month;
      let dia = day.getDate();
      dia = dia.toString();
      if(dia.length == 1) dia = '0' + dia;
      return day.getFullYear() + '-' + month + '-' +dia;
    },

    getDayWeek(days, day_week){
      let day = this.dia[day_week];
      let day_select = new Date();
      days.forEach( (d) => {
        let day_date = new Date(d);
        let name_day = this.dia[day_date.getDay()];
        if(name_day == day){
          day_select = day_date;
        }
      });
      let month = day_select.getMonth() +1;
      month = month.toString();
      if(month.length == 1) month = '0' + month;
      let dia = day_select.getDate();
      dia = dia.toString();
      if(dia.length == 1) dia = '0' + dia;
      return day_select.getFullYear() + '-' + month + '-' +dia;
    },

    getDateArr(travel_date){
      let day = new Date(travel_date + 'T00:00:00')
      let previous = new Date(day);
      let daysNumber = [];
      for(let i=0; i<7; i++){
        daysNumber.push(new Date(previous.setDate(previous.getDate()-1)));
      }
      let days = [];
      days[0] = day;
      for(let i=0; i<6; i++){
        days[i+1] = new Date(daysNumber[i]);
      }
      return days
    },

    // Retorna la fecha en formatio dia, mes y hora
    getDateName(travel_day, travel_time){
      let fecha = new Date(this.getDate(travel_day, travel_time));
      let meses = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      return dia + ' ' + meses[mes] +' '+fecha.getFullYear() +' ' +this.getHourTravel(travel_time);
    },

    getHourTravel(time){
      // 00:00
      let hour = parseInt(time[0]+time[1]);
      let horario = 'AM'
      if(hour > 12){
        hour = hour - 12;
        horario = 'PM';
      }
      let hora = hour.toString();
      if(hora.length == 1) hora = '0'+hora;
      let min = time[3] + time[4];
      return hora +':'+min+' '+ horario;
    },

    getTramoActual(tramo){
      let hoy = new Date();
      let fecha = new Date(this.getDate(tramo.fecha_viaje, tramo.hora_viaje));
      if(hoy.getTime()<fecha.getTime()) return true;
      else return false;
    },

    getDate(day, hour){
      let dia = day.toString() + 'T' + hour.toString();
      return dia;
    },

    isPlanAvailableForDelete(plan) {
      if(plan.transporte_planificacion_cadencia.nombre_cadencia == "Semanal"){
        return this.hasRegistrationForPlanStarted(this.findInicioTramo(plan));
      } else {
        return false;
      }
    },

    findInicioTramo(plan) {
      let tramo = plan.transporte_tramo[0];
      let days = this.getDateArr(tramo.fecha_viaje);
      let day = this.getDayWeek(days, plan.tranpsorte_planificacion_detalle.inicio_periodo_semanal);
      return day;
    },

    hasRegistrationForPlanStarted(plan_registration_day) {
      let today = new Date();
      let day_check = new Date(plan_registration_day+'T00:00:00');
      if (day_check.getTime() < today.getTime()) return false;
      else return true
    },

    editarPlan(p){
      this.setEditingPlanAction(p);
      this.$emit("open-modal-edit");
    },

    eliminarPlan(planificacion) {
      if (this.tramosActivos(planificacion.id_planificacion) == true) {
        this.open_modal_status = true;
        this.modal_status_msg = "Lo sentimos, no se puede eliminar una planificación con tramos activos.";
        this.modal_status = false;
      } else {
        this.id_delete = planificacion.id_planificacion;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estás seguro(a) de eliminar esta planificación?";
        this.modal_status = true;
      }
    },

    // Recorre tramos y verifica si esta activo (ahora esto se hace por response de ws)
    tramosActivos(id_planificacion) {
      let tramos_activos = 0;
      this.viajesEnCurso_list.forEach(plan => {
        if (plan.transporte_planificacion.id_planificacion == id_planificacion && plan.estado == "Activa") {
          tramos_activos++;
        }
      });
      return tramos_activos > 0 ? true : false;
    },

    // Eliminar planificacion
    async acceptQuestion() {
      this.show_spinner = true;
      const payload = {
        idPlanificacion: this.id_delete,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario
      };
      const deletedPlan = await this.deletePlanificacionAction(payload);
      this.show_spinner = false;
      this.open_question_modal = false;  

      if (deletedPlan) {        
        if(deletedPlan.nombre_planificacion == 'in_use') {
          this.toastDeletePlanErrorActiveTramos();
        } else {
          this.toastDeletePlanSuccess();
        }
      } else {
        this.toastDeletePlanErrorUnknown();
      }
    },

    // Cancela eliminar
    cancelQuestion() {
      this.open_question_modal = false;
    },

    // Termina eliminar
    closeStatus() {
      this.open_modal_status = false;
    },

    toastDeletePlanErrorActiveTramos() {
      this.$toast.open({
        type: 'error',
        message: 'Lo sentimos, no es posible eliminar una planificación con tramos activos.',
        position: 'top-right',
        duration: 5000,
      });
    },
    toastDeletePlanErrorUnknown() {
      this.$toast.open({
        type: 'error',
        message: 'Ocurrió un error al eliminar la planificación. Por favor inténtalo nuevamente.',
        position: 'top-right',
        duration: 5000,
      });
    },
    toastDeletePlanSuccess() {
      this.$toast.open({
        type: 'success',
        message: 'Planificación eliminada correctamente.',
        position: 'top-right',
        duration: 5000,
      });
    }

  },
};
</script>

<style></style>
